.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    margin-bottom: 5rem;
    text-align: center;
    
}

.score {
    position: absolute;
    top: 10%;
    right: 10%;
    
}

.singleOption {
    max-width: 90%;
    /* width: 60%; */
    min-width: 40%;
    height: 50px;
    padding: 15px 20px;
    margin: 10px;
    box-shadow: 0 0 2px black;
  }

  .select {
    background-color: rgb(7, 207, 0);
    color: white;
    box-shadow: 0 0 1px black;
  }
  
  .wrong {
    background-color: rgb(233, 0, 0);
    color: white;
    box-shadow: 0 0 1px black;
  }

  .blow-btns{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  @media screen and  (max-width:700px) {


    .container {
      grid-template-columns: 1fr ;

    }
    
  }

  @media screen and (max-width:400px) {

    .container {
      margin-bottom: 1rem;

    }
    
  }